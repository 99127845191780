import React from "react";
import { ChakraProvider, Flex, theme } from "@chakra-ui/react";
import "./App.css";
import { Form } from "./components/form";
import { DecisionTreeModel } from "./model/decision_tree";
import { Result } from "./components/result";
import { Logo } from "./components/logo";
import { InputData } from "./model/types";

const App = () => {
  const [loading, setLoading] = React.useState(false);

  const [inputData, setInputData] = React.useState<InputData>({
    vypit: true,
    sszv: 0,
    nst: 0,
    sam: 0,
  });

  const [result, setResult] = React.useState("");

  const handleSubmit = React.useCallback(
    (inputData) => {
      if (inputData.sszv === 0 || inputData.nst === 0) return;
      setInputData(inputData);
      setLoading(true);
      const result = new DecisionTreeModel().predict(inputData);
      setResult(result);
      setLoading(false);
    },
    [inputData]
  );

  return (
    <ChakraProvider theme={theme}>
      <Flex direction="column" align="center">
        <Flex direction="column" align="start" maxWidth="900px">
          <Logo />
          <Form
            onSubmit={(inputData) => {
              handleSubmit(inputData);
            }}
            loading={loading}
          />
          <Result result={result} inputData={inputData} />
        </Flex>
      </Flex>
    </ChakraProvider>
  );
};

export default App;
