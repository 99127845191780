import React from "react";
import {
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormHelperText,
} from "@chakra-ui/react";

interface InputProps {
  id: string;
  isRequired?: boolean;
  setNumberValue: (value: number) => void;
  label: string;
  helperText?: string;
  precision: number;
  step?: number;
  min?: number;
  max?: number;
}

export const Input: React.FC<InputProps> = ({
  id,
  isRequired,
  setNumberValue,
  label,
  helperText,
  precision,
  min,
  max,
  step,
}) => {
  const [value, setValue] = React.useState(-1);
  return (
    <FormControl
      variant="floating"
      id={id}
      isRequired={isRequired}
      isInvalid={isRequired && value === min}
      width={["45%", "23%"]}
    >
      <FormLabel>{label}</FormLabel>
      <NumberInput
        defaultValue={0}
        precision={precision}
        min={min}
        max={max}
        step={step}
        onChange={(_valueAsString, valueAsNumber) => {
          setValue(valueAsNumber);
          setNumberValue(valueAsNumber);
        }}
      >
        <NumberInputField />
        {min !== undefined && max !== undefined && (
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        )}
      </NumberInput>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
