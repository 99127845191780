import {
  buildAlgorithmConfiguration,
  growTree,
  getTreePrediction,
} from "tree-garden";
import { training_data } from "./data";
import { InputData, Metrics } from "../types";

export class DecisionTreeModel {
  loadData = () => {};

  predict = (inputData: InputData) => {
    const includeSam = inputData.sam !== 0;
    const includedAttributes = includeSam
      ? [Metrics.VYPIT, Metrics.SSZV, Metrics.NST, Metrics.SAM]
      : [Metrics.VYPIT, Metrics.SSZV, Metrics.NST];

    const algorithmConfig = buildAlgorithmConfiguration(training_data, {
      treeType: "classification",
      includedAttributes: includedAttributes,
      biggerScoreBetterSplit: true,
    });
    const tree = growTree(algorithmConfig, training_data);

    const predictionData = {
      vypit: inputData.vypit ? 1 : 0,
      sszv: inputData.sszv,
      nst: inputData.nst,
      sam: inputData.sam,
    };
    console.log(">>>>>>>>>>inputData=", predictionData);

    const prediction = getTreePrediction(predictionData, tree, algorithmConfig);

    console.log(">>>>>>>>>>result=", prediction);
    return prediction.toString();
  };
}
