import React from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";

interface BooleanInputProps {
  id: string;
  setBooleanValue: (value: boolean) => void;
  label: string;
  helperText?: string;
}

export const BooleanInput: React.FC<BooleanInputProps> = ({
  id,
  setBooleanValue,
  label,
  helperText,
}) => {
  const [value, setValue] = React.useState("true");
  return (
    <FormControl variant="floating" id={id} isRequired width={["45%", "23%"]}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        onChange={(value) => {
          setValue(value);
          setBooleanValue(value === "true");
        }}
        value={value}
      >
        <Stack direction="row">
          <Radio size="lg" value="true">
            Yes
          </Radio>
          <Radio size="lg" value="false">
            No
          </Radio>
        </Stack>
      </RadioGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
