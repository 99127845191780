export const training_data = [
  {
    _class: "л",
    vypit: 0,
    sszv: 2,
    nst: 38.3,
    sam: 1.448,
    _label: 1,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 40.6,
    sam: 11.317,
    _label: 2,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 36.4,
    sam: 1.619,
    _label: 3,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 34,
    sam: 0.714,
    _label: 4,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 42.5,
    sam: 1.486,
    _label: 5,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 37.4,
    sam: 5.046,
    _label: 6,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 32.1,
    sam: 2.132,
    _label: 7,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 40.7,
    sam: 1.122,
    _label: 8,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 43.8,
    sam: 3.216,
    _label: 9,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 2,
    nst: 43.3,
    sam: 1.214,
    _label: 10,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 40.8,
    sam: 2.361,
    _label: 11,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 44.4,
    sam: 2.058,
    _label: 12,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 43.6,
    sam: 2.973,
    _label: 13,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 35.8,
    sam: 1.604,
    _label: 14,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 2,
    nst: 45.4,
    sam: 1.112,
    _label: 15,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 39.9,
    sam: 1.838,
    _label: 16,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 39.6,
    sam: 1.32,
    _label: 17,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 40.7,
    sam: 1.572,
    _label: 18,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 45.3,
    sam: 2.027,
    _label: 19,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 41.2,
    sam: 1.877,
    _label: 20,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 42.3,
    sam: 2.518,
    _label: 21,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 39.5,
    sam: 1.782,
    _label: 22,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 44.6,
    sam: 6.988,
    _label: 23,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 39.8,
    sam: 5.198,
    _label: 24,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 38.2,
    sam: 1.92,
    _label: 25,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 36.6,
    sam: 2.086,
    _label: 26,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 41,
    sam: 1.567,
    _label: 27,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 51.9,
    sam: 2.668,
    _label: 28,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 0,
    nst: 36.6,
    sam: 3.503,
    _label: 29,
  },
  {
    _class: "л",
    vypit: 0,
    sszv: 1,
    nst: 45.6,
    sam: 1.956,
    _label: 30,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 2,
    nst: 39.7,
    sam: 3.516,
    _label: 31,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 1,
    nst: 40.9,
    sam: 1.674,
    _label: 32,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 1,
    nst: 39,
    sam: 5.439,
    _label: 33,
  },
  {
    _class: "с",
    vypit: 1,
    sszv: 3,
    nst: 46.2,
    sam: 2.784,
    _label: 34,
  },
  {
    _class: "с",
    vypit: 1,
    sszv: 0,
    nst: 41.9,
    sam: 1.614,
    _label: 35,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 2,
    nst: 44.2,
    sam: 1.332,
    _label: 36,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 1,
    nst: 41.5,
    sam: 1.473,
    _label: 37,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 2,
    nst: 37.2,
    sam: 2.49,
    _label: 38,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 0,
    nst: 40.6,
    sam: 2.073,
    _label: 39,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 2,
    nst: 49.8,
    sam: 0.773,
    _label: 40,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 1,
    nst: 36.9,
    sam: 1.997,
    _label: 41,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 0,
    nst: 38.4,
    sam: 1.979,
    _label: 42,
  },
  {
    _class: "с",
    vypit: 1,
    sszv: 2,
    nst: 44.1,
    sam: 0.671,
    _label: 43,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 2,
    nst: 44.9,
    sam: 6.193,
    _label: 44,
  },
  {
    _class: "с",
    vypit: 1,
    sszv: 1,
    nst: 40.7,
    sam: 1.001,
    _label: 45,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 1,
    nst: 40.6,
    sam: 1.037,
    _label: 46,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 0,
    nst: 39.4,
    sam: 4.941,
    _label: 47,
  },
  {
    _class: "с",
    vypit: 0,
    sszv: 3,
    nst: 43.3,
    sam: 2.247,
    _label: 48,
  },
  {
    _class: "т",
    vypit: 1,
    sszv: 2,
    nst: 52,
    sam: 2.954,
    _label: 49,
  },
  {
    _class: "т",
    vypit: 1,
    sszv: 3,
    nst: 41.3,
    sam: 1.279,
    _label: 50,
  },
  {
    _class: "т",
    vypit: 1,
    sszv: 3,
    nst: 47.9,
    sam: 1.658,
    _label: 51,
  },
  {
    _class: "т",
    vypit: 1,
    sszv: 1,
    nst: 46.8,
    sam: 1.396,
    _label: 52,
  },
  {
    _class: "т",
    vypit: 1,
    sszv: 3,
    nst: 45.1,
    sam: 0.832,
    _label: 53,
  },
  {
    _class: "т",
    vypit: 0,
    sszv: 2,
    nst: 48.1,
    sam: 2.016,
    _label: 54,
  },
  {
    _class: "т",
    vypit: 0,
    sszv: 2,
    nst: 41.6,
    sam: 0.945,
    _label: 55,
  },
  {
    _class: "т",
    vypit: 1,
    sszv: 3,
    nst: 30.3,
    sam: 1.553,
    _label: 56,
  },
  {
    _class: "т",
    vypit: 1,
    sszv: 2,
    nst: 37.2,
    sam: 1.005,
    _label: 57,
  },
  {
    _class: "т",
    vypit: 1,
    sszv: 3,
    nst: 37.7,
    sam: 1.17,
    _label: 58,
  },
  {
    _class: "т",
    vypit: 0,
    sszv: 2,
    nst: 49.5,
    sam: 5.439,
    _label: 59,
  },
  {
    _class: "т",
    vypit: 1,
    sszv: 2,
    nst: 18.6,
    sam: 1.511,
    _label: 60,
  },
  {
    _class: "т",
    vypit: 1,
    sszv: 1,
    nst: 33.4,
    sam: 3.479,
    _label: 61,
  },
  {
    _class: "т",
    vypit: 0,
    sszv: 2,
    nst: 37.9,
    sam: 3.061,
    _label: 62,
  },
];
