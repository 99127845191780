export interface InputData {
  vypit: boolean;
  sszv: number;
  nst: number;
  sam: number;
}

export enum Metrics {
  VYPIT = "vypit",
  SSZV = "sszv",
  NST = "nst",
  SAM = "sam",
}

export const Labels = {
  [Metrics.VYPIT]: "Випіт",
  [Metrics.SSZV]: "ССЗВ",
  [Metrics.NST]: "НСТ",
  [Metrics.SAM]: "С (А/М)",
};
