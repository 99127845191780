import React from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { InputData, Labels, Metrics } from "../../model/types";
interface ResultProps {
  result: string;
  inputData: InputData;
}

export const Result: React.FC<ResultProps> = ({ result, inputData }) => {
  if (!!result)
    return (
      <SimpleGrid
        columns={4}
        spacing="20px"
        minChildWidth="20%"
        margin="30px"
        width="100%"
      >
        <Text>
          {Labels[Metrics.VYPIT]}:{" "}
          <strong>{inputData.vypit ? "Yes" : "No"}</strong>
        </Text>
        <Text>
          {Labels[Metrics.SSZV]}: <strong>{inputData.sszv}</strong>
        </Text>
        <Text>
          {Labels[Metrics.NST]}: <strong>{inputData.nst}</strong>
        </Text>
        <Text>
          {Labels[Metrics.SAM]}: <strong>{inputData.sam}</strong>
        </Text>
        <Text fontWeight="bold" fontSize="xx-large">
          Result = {result}
        </Text>
      </SimpleGrid>
    );
};
