import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import { BooleanInput } from "./input/boolean";
import { Input } from "./input/number";
import { InputData, Labels, Metrics } from "../../model/types";

interface FormProps {
  loading: boolean;
  onSubmit: (inputData: InputData) => void;
}

export const Form: React.FC<FormProps> = ({ loading, onSubmit }) => {
  const [vypit, setVypit] = React.useState(true);
  const [sszv, setSszv] = React.useState(0);
  const [nst, setNst] = React.useState(0);
  const [sam, setSam] = React.useState(0);

  return (
    <Flex p={8} direction="column" gap={5}>
      <Flex gap={3} wrap="wrap" direction="row" justifyContent="space-between">
        <BooleanInput
          id={Metrics.VYPIT}
          label={Labels[Metrics.VYPIT]}
          setBooleanValue={(value) => setVypit(value)}
        />
        <Input
          isRequired
          id={Metrics.SSZV}
          label={Labels[Metrics.SSZV]}
          setNumberValue={(value) => setSszv(value)}
          precision={0}
          min={0}
          max={10}
        />
        <Input
          isRequired
          id={Metrics.NST}
          label={Labels[Metrics.NST]}
          setNumberValue={(value) => setNst(value)}
          precision={2}
          step={0.1}
          min={0}
          max={100}
        />
        <Input
          id={Metrics.SAM}
          label={Labels[Metrics.SAM]}
          setNumberValue={(value) => setSam(value)}
          precision={3}
          step={0.001}
          min={0}
          max={100}
        />
      </Flex>
      <Button
        isLoading={loading}
        colorScheme="blue"
        onClick={() => {
          onSubmit({ vypit: vypit, sszv: sszv, nst: nst, sam: sam });
        }}
      >
        Send
      </Button>
    </Flex>
  );
};
